<template>
  <div class="head_panel" :style="panelStyle">
    <div class="main">
      <div class="e_name">东华汽贸公司</div>
      <div class="year">
        <l-select v-model="condition.year" all-label="--所有--" :data="yearList" @change="yearChange"></l-select>
      </div>
      <div class="enterprise" v-if="userInfo.eGroupCode">
        <l-select
          v-if="userInfo.eGroupCode"
          all-label="--所有--"
          v-model="condition.enterpriseCode"
          :data="userInfo.eGroupChildren"
          :prop="['enterpriseCode', 'enterpriseName']"
          @change="enterpriseChange"
        ></l-select>
      </div>
      <div class="btn-area">
        <el-button icon="el-icon-s-marketing" size="small" @click="showRank(1)">{{ monitorData.isEGroup ? '企业排名' : '部门排名' }}</el-button>
        <el-button icon="el-icon-user-solid" size="small" @click="showRank(3)">员工排名</el-button>
        <el-button icon="el-icon-money" size="small" @click="showRank(5)">奖励金额</el-button>
        <el-button icon="el-icon-money" size="small" @click="showRank(6)">已发金额</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import rankCarousel from './rankCarousel'

export default {
  computed: {
    ...mapState(['userInfo', 'setting'])
  },
  props: {
    monitorData: {
      type: Object,
      default: null
    },
    config: {
      type: Object,
      default: null
    },
    condition: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      panelStyle: {},
      yearList: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.initYearList()
      this.panelStyle = {
        top: this.config.space + 'px',
        right: this.config.unit + this.config.space * 2 + 'px',
        height: this.config.head.height + 'px',
        width: this.config.width - this.config.unit * 2 - this.config.space * 4 + 'px'
      }
    },
    initYearList() {
      var start = 2021
      var end = new Date().getFullYear()
      if (end - start < 5) {
        end += 5 - (end - start)
      }
      for (var i = start; i <= end; i++) {
        this.yearList.push({
          code: i,
          text: i + '年'
        })
      }
    },
    yearChange() {
      this.$emit('change', this.condition)
    },
    enterpriseChange() {
      this.$emit('change', this.condition)
    },
    panelClick(data) {
      this.$site.showCarList(Object.assign({ noStatus7: true, carStatus: 1 }, data))
    },
    async showRank(index) {
      await this.$lgh.openDialog({
        ins: rankCarousel,
        title: '建议评价',
        data: {
          config: this.config,
          monitorData: this.monitorData,
          index: index - 1,
          year: this.year
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.head_panel {
  position: absolute;
  border-radius: 10px;
  color: #fff;
  background-color: #282839;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .e_name {
      display: none;
      margin-left: 15px;
      cursor: pointer;
    }
    .year {
      margin-left: 15px;
      .el-select {
        width: 95px;
      }
    }
    .enterprise {
      margin-left: 15px;
      .el-select {
        width: 155px;
      }
    }
    .btn-area {
      flex: 1;
      display: flex;
      margin-right: 15px;
      justify-content: right;
      .el-button {
        background-color: #282839;
        border: 1px solid #444;
        color: #fff;
      }
      .el-button:hover {
        background-color: #6e6e9c;
      }
    }
  }
}
</style>
